@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: 'Roboto';
}

@media only screen and (min-width:900px) {
  .home {
    display: block;
    margin: 0 auto;
  }
}

@media only screen and (max-width:899px) {
  .home {
    position: relative;
    /* top: 800px; */
  }

  .sidebar {
    position: relative;
  }
}

@media screen and (max-width:768px) {
  .username-u {
    position: relative;
    top: -40px;
    left: 45px;
  }

  .username-u .tl {
    max-width: 100%;
    width: 200px;
  }
}
