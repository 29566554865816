@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');

body {
  font-family: 'Roboto';
}

@media only screen and (min-width:900px) {
  .home {
    display: block;
    margin: 0 auto;
  }
}

@media only screen and (max-width:899px) {
  .home {
    position: relative;
    /* top: 800px; */
  }

  .sidebar {
    position: relative;
  }
}

@media screen and (max-width:768px) {
  .username-u {
    position: relative;
    top: -40px;
    left: 45px;
  }

  .username-u .tl {
    max-width: 100%;
    width: 200px;
  }
}